@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  @apply h-full w-full  bg-black text-white;
}

#root {
  @apply scroll-smooth;
}

.qr-code-scanner {
  @apply h-full w-full;

  div {
    @apply !h-full;

    video {
      @apply !w-auto !max-w-none;
    }
  }
}
