@tailwind components;

@layer components {
  .select-container {
    @apply relative cursor-pointer;

    .icon-container {
      @apply absolute top-2/4 right-3 -translate-y-2/4;
    }

    .select {
      @apply w-full cursor-pointer appearance-none rounded-[0.375rem] bg-white bg-none py-2 px-3 text-sm text-black shadow-md ring-1 ring-[#EEEEEE] placeholder:text-black hover:ring-primary-500 focus:outline-none;

      &.disabled {
        @apply bg-[#E2E2E2] text-black ring-0;
      }

      &.error {
        @apply !ring-error;
      }

      &.success {
        @apply !ring-success;
      }

      &:focus-visible {
        @apply outline-2 outline-offset-4 outline-[#EEEEEE] ring-primary-500;
      }
    }
  }
}
