@tailwind components;

@layer components {
  .btn {
    @apply flex h-11 w-full cursor-pointer items-center justify-center space-x-4 rounded-[0.375rem] font-medium transition-colors;

    &.icon {
      @apply w-11;
    }

    &:focus-visible {
      @apply outline-2 outline-offset-4 outline-[#EEEEEE];
    }

    &.primary {
      @apply bg-primary-500 text-white active:bg-primary-400;

      &:hover {
        @apply bg-primary-400;
      }

      &.disabled {
        @apply bg-grey-300;
      }

      .icon {
        @apply fill-white;
      }
    }

    &.secondary {
      @apply bg-white text-primary-500 ring-1 ring-primary-500 active:bg-primary-400;

      &:hover {
        @apply bg-primary-400 text-white;

        .icon {
          @apply fill-white;
        }
      }

      &.disabled {
        @apply bg-white text-primary-100 ring-primary-100;

        .icon {
          @apply fill-primary-100 text-primary-100;
        }
      }

      .icon {
        @apply fill-primary-400;
      }
    }

    &.ghost {
      @apply h-fit w-fit p-1;

      &:hover {
        @apply bg-[#EEEEEE];
      }
    }
  }
}
